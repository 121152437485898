<script>
export default {
  data() {
    return {
      active:true,
      textarea1: '',
      textarea2: '',
      messages: [{
        ok: 0,
        id: '00000',
        sender: "ai",
        introduce: "Hello, here you can get all-round AI application services for the oil and gas industry. <br>What can I do for you today?",
        resource: "",
        url:'nan',
        isSendingMessage: 0,
        language:''
      },
      ],
    }
  },
  methods:{
    toGPT(){
      window.open("/gptAssistants")
    },
    toNews(){
      window.open("https://www.oilgasinfoai.com/")
    },
    resizeTextarea() {
      const textarea = document.getElementById('text');
      if (textarea === null) {
        console.error('没有找到id为text的textarea元素');
        return;
      }
      const computedStyle = window.getComputedStyle(textarea);
      const height = computedStyle.height;
      const height1 = parseInt(height, 10);
      console.log("height",height)
      console.log("height",height1)


      const clientheight = window.innerHeight * 0.2;
      if (isNaN(height1) || height1 < clientheight) {
        textarea.style.height = 'auto';
        textarea.style.height = textarea.scrollHeight + 'px';
        if (this.newMessage.trim() === '') {
          textarea.style.height = '22px';
        }
        textarea.scrollTop = 0;
      }
    },
    sendMessage(){
      this.$emit("send1",this.textarea2)
      this.textarea2=""
    },
    handleInput() {
      // 计算输入框内容的行数
      this.currentRows = this.textarea2.split('\n').length;
      console.log("currentRows",this.currentRows)
    },
    NavigatTo(refName){
      console.log("this.$parent", this.$parent)
      // 使用 this.$refs 访问目标元素
      let distanceY = this.$parent.$refs[refName].offsetTop;
      console.log("distanceY", distanceY)
      console.log("window.scrollY", window.innerHeight)
      // 使用 scrollIntoView 方法滚动到该元素s
      window.scrollBy({
        top: distanceY-window.scrollY-window.innerHeight*0.07, // 向下滚动100像素
        left: 0,
        behavior: 'smooth'
      });
    }
  }
}
</script>

<template>
<div class="NavigationContainer">
  <div  class="Navigation">
    <div class="N1" @click="toGPT">
      <div class="title1">
        GPT Assistants
      </div>
      <div class="title2">
        One-click access to AI tools
      </div>

    </div>
    <div class="N1" id="N2" @click="toNews">
      <div class="title1">
        Oil & Gas Industry News
      </div>
      <div class="title2">
        Free,timely oil & gas news,reports,etc.
      </div>
    </div>
  </div>
  <div  class="inputContent">
    <el-input
        type="textarea"
        :autosize="{ minRows: 1, maxRows: 9}"
        placeholder="Search for any questions"
        resize="none"
        @input="resizeTextarea"
        style="font-size: 1.2vw;overflow-y: hidden;min-height: 20vh;font-family: 'Times New Roman'"
        v-model="textarea2">
    </el-input>
    <img @click="sendMessage" style="height: 3vw;width: 3vw;position: absolute;bottom: 4vh;right: 2vw" src="@/assets/homeSend.png">
  </div>
  <div  class="bottomContent">
    <img style="height: 2vw;width: 2vw" src="@/assets/homebanner3.png">
    <span >
      <span style="color:#117cd2;">Anvi</span><span style="color:#f0382a;">sion</span> Services:
    </span>
    <div class="item"  @click="NavigatTo('consultMove')" >
      Smart Oilfield
    </div>
    <div class="item" @click="NavigatTo('professional')">
      Customized AI
    </div>
    <div class="item" @click="NavigatTo('software')">
      Software Products & Development
    </div>
    <div class="item" @click="NavigatTo('mediaMove')">
      Intelligent Multimedia
    </div>
  </div>
  <transition appear name="expand">
    <div style="position: absolute;bottom: -6vh;height: 2vw;width: 2vw;left: 49%">
      <div style="position: relative;height: 2vw;width: 2vw">
        <img src="@/assets/arrow.png" class="animated-element" style="width: 1.5vw;height: 1.5vh;position: absolute">
        <img src="@/assets/arrow.png" class="animated-element2" style="width: 1.5vw;height: 1.5vh;position: absolute">
        <img src="@/assets/arrow.png" class="animated-element3" style="width: 1.5vw;height: 1.5vh;position: absolute">
        <img src="@/assets/arrow.png" class="animated-element4" style="width: 1.5vw;height: 1.5vh;position: absolute">
      </div>
    </div>

  </transition>
</div>
</template>

<style scoped lang="scss">
.NavigationContainer{
  width: 100%;
  height: 80vh;
  position: relative;
  .Navigation{
    width: 100%;
    height: 30vh;
    //background-color: white;
    display: grid;
    grid-template-columns: minmax(0,1fr) minmax(0,1fr);
    .N1 {
      cursor: pointer;
      //border:1px solid gray;
      background-color: #1b1e76;
      margin: 1vh 1vw;
      padding: 3vh 1vw 7vh 3vw;
      border-radius: 9px;
      color: white;
      text-align: left;
      display: grid;
      align-items: center;
      background-image: url("../../assets/homebanner1.jpg");
      background-size: cover;
    }
    #N2{
      background-image: url("../../assets/homebanner2.jpg");
    }
    .title1{
      font-size: 2vw;
      font-weight: bolder;
      color: #6262f4;
      font-family: initial;
      text-align: left;
    }
    .title2{
      color: #000000;
      font-size: 1vw;
      //font-weight: bolder;
      font-family: "Times New Roman";
      text-align: left;

    }
  }
  .inputContent{
    font-size: 2vw;
    padding:3vh 1vw ;
    height: fit-content;
    border-radius: 9px;
    position: relative;
    ::v-deep .el-textarea__inner{
      border-radius: 9px;
      font-family: "Times New Roman";
      padding: 1vh 5vw 1vh 1vw;
      min-height: 20vh !important;
    }
  }
  .bottomContent{
    display: flex;
    position: absolute;
    bottom: 19vh  ;
    padding:0 1vw;
    font-size: 1.2vw;
    font-weight: bolder;
    align-items: center;
    .item{
      font-weight: normal;
      cursor: pointer;
      margin-left: 1vw;
      background-color: rgb(246, 246, 246);
      padding: 1vh 1vw;
      border-radius: 9px;
    }
  }
  .animated-element{
    //height: 10vh;
    animation: jiantou 3s linear infinite 0S;
  }
  .animated-element2{
    //height: 10vh;
    animation: jiantou 3s linear infinite 0.8s;
  }
  .animated-element3{
    //height: 10vh;
    animation: jiantou 3s linear infinite 2s;
  }
  .animated-element4{
    //height: 10vh;
    animation: jiantou 3s linear infinite 3s;
  }
  .expand-enter-active {
    animation: bounceIn 5s linear both;
  }
  .expand-height-enter-active,
  .expand-height-leave-active {
    transition: height 3s ease;
  }

  .expand-height-enter-from,
  .expand-height-leave-to {
    height: 58vh;
  }

  .expanded {
    height: 75vh;
    overflow: hidden;
  }
  .communicate {
    height: 100%;
    width: 64vw;
    padding: 0 1vw;
    margin: 1.5vh auto 0 auto;
    background: transparent;
    font-size: 1.1vw;
    overflow-y: auto;
    overflow-x: hidden;
    //border: 5px solid black;
  }
  .communicate::-webkit-scrollbar-track-piece {
    background-color: #f8f8f800;
  }
  .communicate::-webkit-scrollbar {
    width: 6px;
    transition: all 2s;
  }
  .communicate::-webkit-scrollbar-thumb {
    background-color: #dddddd;
    border-radius: 100px;
  }
  .communicate::-webkit-scrollbar-thumb:hover {
    background-color: #bbb;
  }
  .communicate::-webkit-scrollbar-corner {
    background-color: rgba(255, 255, 255, 0);
  }
}
</style>
