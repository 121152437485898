<script>
import homebox from "@/views/component/homebox.vue"

export default {
  components:{
    homebox
  },
  methods:{
    clickConsult(itemName){
      this.$emit("clickConsult",itemName)
    },
  }

}
</script>

<template>
<div class="softwarecontainer">
  <div class="title">
    3. Smart Oilfield Software Products and Development Services
  </div>
  <div class="introduce">
    <div>
      <span >Provide efficient, intelligent, and reliable full-scenario oilfield digitalization products and software development support.</span><br>
      <span style="color: #eb7c31">To have further product display and communication,<span  @click="clickConsult('Smart Oilfield Software Products & Development')" style="color:white;background-color: #282d7d;padding: 5px;border-radius: 9px;font-weight: bolder;font-size: 1.1vw">Contact Us</span>.</span>
    </div>
  </div>
  <div class="itemcontainer">
    <div class="item">
      <a href="/softwareOilfield" target="_blank">
        <homebox class="homebox" :title="'Smart Oilfield Software Products'" :imgpath="require('@/assets'+'/software2.png')"></homebox>
      </a>

    </div>
    <div class="item">
      <a href="/softwareCustomized" target="_blank">
      <homebox class="homebox" :title="'Software Customized Services'" :imgpath="require('@/assets'+'/software1.png')"></homebox>
      </a>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.softwarecontainer{
  .title{
    margin: 0 auto;
    text-align: center;
    height: 8.2373300371vh;
    opacity: 1;
    font-size: 3vw;
    font-family: "Times New Roman";
    font-weight: 700;
    line-height: 4.2373300371vh;
  }
  .introduce{
    font-size: 1.5vw;
    padding: 0 2vw 2vw 2vw;
    line-height: 2vw;
    margin: auto;
    display: flex;
    width: fit-content;
  }
  .itemcontainer{
    display: grid;
    grid-template-columns: minmax(0,1fr) minmax(0,1fr);
    .item{
      padding: 0 3vw;
    }
    .homebox{
      height: 45vh;
    }
  }
}
</style>
