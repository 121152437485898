<script>
export default {
  data(){
    return{
      textarea2:''
    }
  },
  methods:{
    msSend1(){
      console.log("12312312321")
      this.$emit("msSend1",this.textarea2)
    }
  }

}
</script>

<template>
<div class="NaviContainer" >
  <div style=" height: 34vh;width: 100%;display: grid;grid-template-columns: minmax(0,1fr);">
    <a class="N" href="/gptAssistantsMobile">
      <!--    <div class="N">-->
      <div class="title1" >GPT Assistants</div>
      <div class="title2" >One-click access to AI tools</div>
      <!--    </div>-->
    </a>

    <a class="N" id="N2" href="https://www.oilgasinfoai.com/">
      <!--    <div class="N" id="N2">-->
      <div class="title1" >Oil & Gas Industry News</div>
      <div class="title2" >Free,timely oil & gas news,reports,etc.</div>
      <!--    </div>-->
    </a>

  </div>
  <div style="width: 80vw;height: fit-content;margin-left: 10vw;position: relative">
    <el-input style="margin-top: 20vh;border-radius: 15px" rows="4" type="textarea" v-model="textarea2" resize="none" placeholder="Search for any questions">

    </el-input>
    <img  @click="msSend1" style="width: 9vw;height: 9vw;position: absolute;bottom: 1.5vw;right: 1.5vw" src="@/assets/homeSend.png">
  </div>


</div>
</template>

<style scoped lang="scss">
.NaviContainer{
  width: 100%;
  height:fit-content;

  .N{
    height: 17vh;
    //background-color: white;
    margin: 0vh 10vw;
    margin-top: 2vh;
    padding: 0 2vw;
    border-radius: 9px;
    background-image: url("../../assets/homebanner1.jpg");
    background-size: cover;
    text-align: left;
    .title1{
      font-size: 6vw;
      margin-top: 4vh;
      color: #6262f0;
    }
    .title2{
      font-weight: normal;
      font-size: 4vw;
      margin-top: 2vh;
      line-height: 6vw;
      color: black;
    }

  }
  #N2{
    background-image: url("../../assets/homebanner2.jpg");
  }
  ::v-deep .el-textarea__inner{
    padding: 3vw 10vw 3vw 4vw !important;
    border-radius: 15px !important;
  }
}

</style>
