<script>
import {vSlidenIn} from "@/utils/vSlidenIn";
export default {
  directives:{
    slidenIn:vSlidenIn
  },
  data(){
    return {
      cilentHeight:window.innerHeight,
      isSticky0:false,
      elements:[
            {
              id:"11",
              name:"AI Development for<br>Oil & Gas Engineering"
            },
            {
              id:"33",
              name:"AI Field Application Services"
            },
            {
              id:"22",
              name:"Enterprise Management GPTs"
            },
        {
          id:"55",
          name:"Enterprise Self-Built<br>AI Platform Services"
        },
            {
              id:"44",
              name:"AI Training And<br>Transformation Consulting"
            },
          ],
      activeID: null
    }
  },
  methods:{
    handleScroll() {
      // 获取所有 titleDesign 元素
      const titleDesignElements = document.querySelectorAll('.titleDesign');
      // 遍历所有 titleDesign 元素
      titleDesignElements.forEach((element) => {
        const style = window.getComputedStyle(element);
        const rect = element.getBoundingClientRect();
        // 检查元素是否为 sticky 定位且顶部位置为 0（或页面顶部）
        if (style.position === 'sticky' && (rect.top < 300 && rect.top>0)) {
          console.log("head----------",'head'+element.id); // 输出 title
          this.activeID = element.id
        }
      });
    },
    navigate1(refName) {
      // 根据不同的 ref 值来进行跳转
      const rect=this.$refs["APP"+refName[0]]
      const distanceY=rect.offsetTop-this.cilentHeight*0.162-window.scrollY;
      window.scrollBy({
        top: distanceY, // 向下滚动100像素
        left: 0,
        behavior: 'smooth'
      });
    },
    clickConsult(itemName){
      this.$emit("clickConsult",itemName)
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    // 页面销毁时移除滚动事件监听
    window.removeEventListener('scroll', this.handleScroll);
  }
}
</script>
<template>
<div class="customizationGPTsContainer" style="color: black">
  <div class="headDesign" ref="headDesign">
  <div v-sliden-in="{duration:600}" class="title111" style="color: black;margin-top: 2vh">
      2. Customized Artificial Intelligence
    </div>
    <div class="heads">
      <div v-for="element,index in elements"  class="head" @click="navigate1(element.id)" :key="element.id" :class="{'is-active': 'APP'+element.id === activeID}" >
        <div style="display: flex;margin: auto">
          <span >{{2+"."+(index+1)}}&nbsp;&nbsp;</span>
          <span v-html="element.name" style="text-align: left;margin: auto;width: fit-content"></span>
        </div>

      </div>
    </div>
  </div>
  <div class="GPTApplication">
    <div v-sliden-in="{}" class="ApplicationCards"  ref="APP1">
      <div class="titleDesign" ref="APP11" id="APP11">
        <div class="title" >
          AI Development for Oil & Gas Engineering
        </div>
        <div class="title2">
          Based on engineering technology enterprises such as Anton.<br>
          <span style="color: #fd7117">For use in more diverse business scenarios,<span @click="clickConsult('AI Development for Oil & Gas Engineering')" style="color:white;background-color: #282d7d;padding: 5px;border-radius: 9px;font-weight: bolder;font-size: 1.1vw">Contact Us</span></span>.
        </div>
      </div>
      <div class="itemDesign" >
        <div class="pro2">
          <!--        油藏-->
          <a href="/gptDesign" target="_blank">
            <div  v-zoom-in="{duration:600}" class="grand2"  style="box-shadow: none">
              <!--              <div v-zoom-in="{duration:600}" class="grand2" @click="oilDesign()" style="box-shadow: none">-->
              <div class="back" style="position: relative">
                <div class="photo">
                  <img class="image" src="@/assets/pro1.jpg" style="border-radius: 9px"/>
                  <div class="mengban" >
                    <div class="title1" style=" left: 4vh;">
                      Engineering Scheme Design Software
                    </div>
                    <div class="title1" style="right: 1vh ;font-weight: unset;font-size: 1.12vw ">
                      MORE &nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                    </div>
                  </div>
                </div>
                <!--                  <div style="margin-left: 15px;">-->
                <!--                    <div class="title1">Design-oriented GPTs</div>-->
                <!--                    <div class="title2">-->
                <!--                      Design-oriented GPTs taking into account various factors in the target region, a design plan is formulated to achieve rational development of Oil & Gas resources.-->
                <!--                    </div>-->
                <!--                  </div>-->

              </div>
            </div>
          </a>
          <!--        智能-->
          <a href="/gptTranslate" target="_blank">
            <div v-zoom-in="{duration:800}" class="grand2" >
              <div class="back" style="position: relative">
                <div class="photo">
                  <img class="image" src="@/assets/pro7.jpg" style="border-radius: 9px"/>
                  <div class="mengban" >
                    <div class="title1" style=" left: 4vh;">
                      Geological Analysis Software
                    </div>
                    <div class="title1" style="right: 1vh ;font-weight: unset;font-size: 1.12vw ">
                      MORE &nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                    </div>
                  </div>
                </div>
                <!--                  <div style="margin-left: 15px;">-->
                <!--                    <div class="title1"> Explanatory GPTs</div>-->
                <!--                    <div class="title2">-->
                <!--                      Explanatory GPTs based on the explanation of Oil & Gas development objectives, it not only facilitates employees to grasp operational information in real-time but also provides robust support for the development process.-->
                <!--                    </div>-->
                <!--                  </div>-->
              </div>
            </div>
          </a>
          <!--        智能-->
          <a href="/gptTechnology" target="_blank">
            <div v-zoom-in="{duration:1000}" class="grand2" >
              <div class="back" style="position: relative">
                <div class="photo">
                  <img class="image" src="@/assets/pro2.jpg" style="border-radius: 9px"/>
                  <div class="mengban" >
                    <div class="title1" style=" left: 4vh;">
                      Production Process Optimization Software
                    </div>
                    <div class="title1" style="right: 1vh ;font-weight: unset;font-size: 1.12vw ">
                      MORE &nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                    </div>
                  </div>
                </div>
                <!--                  <div style="margin-left: 15px;">-->
                <!--                    <div class="title1">Process-related GPTs</div>-->
                <!--                    <div class="title2">-->
                <!--                      Process-related GPTs,not only helps improve development efficiency but also reduces risk losses, ensuring stable development.-->
                <!--                    </div>-->
                <!--                  </div>-->
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div v-sliden-in="{}" class="ApplicationCards"  ref="APP3">
      <div class="titleDesign" ref="APP33" id="APP33">
        <div class="title" >
          AI Field Application Services
        </div>
        <div class="title2" style="padding: 0 0.5vw 2vw 2vw;">
          GPT assisted on-site operations in the oil and gas industry.<br>
          <span style="color: #fd7117">For better usage effect in on-site operations and quick implementation, <span @click="clickConsult('AI Field Application Services')" style="color:white;background-color: #282d7d;padding: 5px;border-radius: 9px;font-weight: bolder;font-size: 1.1vw">Contact Us</span></span>.
        </div>
      </div>
      <div class="itemDesign" >
        <div class="pro12" style="display: grid;grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);">
          <a href="/groundOil" target="_blank">
            <div v-zoom-in="{duration:600}" class="grand1" style="position: relative;height: fit-content" >
              <div class="back">
                <div class="photo">
                  <img class="image" src="@/assets/pro1.png">
                </div>
              </div>
              <div class="back1">
                <div class="title1"> Intelligent Reservoir Geology</div>
                <div class="title2">
                  MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">

                </div>
              </div>
            </div>
          </a>
          <a href="/makeHole" target="_blank">
            <div v-zoom-in="{duration:800}" class="grand1" style="position: relative;height: fit-content" >
              <div class="back">
                <div class="photo">
                  <img class="image" src="@/assets/pro2.png">
                </div>
              </div>
              <div class="back1">
                <div class="title1"> Intelligent Drilling</div>
                <div class="title2">
                  MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                </div>
              </div>
            </div>
          </a>
          <a href="/frackOil" target="_blank">
            <div v-zoom-in="{duration:1000}" class="grand1" style="position: relative;height: fit-content" >
              <div class="back">
                <div class="photo">
                  <img class="image" src="@/assets/pro3.png">
                </div>
              </div>
              <div class="back1">
                <div class="title1"> Intelligent Fracturing</div>
                <div class="title2">
                  MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                </div>
              </div>
            </div>
          </a>
          <a href="/getOil" target="_blank">
            <div v-zoom-in="{duration:600}" class="grand1" style="position: relative;height: fit-content" >
              <div class="back">
                <div class="photo">
                  <img class="image" src="@/assets/pro4.png">
                </div>
              </div>
              <div class="back1">
                <div class="title1"> Intelligent Recovery</div>
                <div class="title2">
                  MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">

                </div>
              </div>
            </div>
          </a>
          <a href="/monitorOil" target="_blank">
            <div v-zoom-in="{duration:800}" class="grand1" style="position: relative;height: fit-content" >
              <div class="back">
                <div class="photo">
                  <img class="image" src="@/assets/pro7.png">
                </div>
              </div>
              <div class="back1">
                <div class="title1"> Formation Monitoring</div>
                <div class="title2">
                  MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">

                </div>
              </div>
            </div>
          </a>
          <a href="/monitorEqu" target="_blank">
            <div v-zoom-in="{duration:1000}" class="grand1" style="position: relative;height: fit-content" >
              <div class="back">
                <div class="photo">
                  <img class="image"  src="@/assets/pro6.png">
                </div>
              </div>
              <div class="back1">
                <div class="title1">Equipment And <br>Facility Monitoring</div>
                <div class="title2">
                  MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div v-sliden-in="{}" class="ApplicationCards"  ref="APP2">
      <div class="titleDesign" ref="APP22" id="APP22">
        <div class="title" >
          Enterprise Management GPTs
        </div>
        <div class="title2">
          Customized Training based on the specific enterprise environment.<br>
          <span style="color: #fd7117">Customize training based on the enterprise's management environment. Only case and demonstration services are offered.<br>For further communication, <span @click="clickConsult('Enterprise Management GPTs')" style="color:white;background-color: #282d7d;padding: 5px;border-radius: 9px;font-weight: bolder;font-size: 1.1vw"> Contact Us</span></span>.
        </div>
      </div>
      <div class="itemDesign" >
        <div class="pro3" style="display: grid;grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);">
          <a href="/gptKnowledge" target="_blank">
            <div v-zoom-in="{duration:600}" class="grand1" style="position: relative;height: fit-content" >
              <div class="back">
                <div class="photo">
                  <img class="image" style="border-radius: 9px" src="@/assets/knowledgenew1.jpg">
                </div>
              </div>
              <div class="back1">
                <div class="title1"> Enterprise<br>Management GPT</div>
                <div class="title2">
                  MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                </div>
              </div>
            </div>
          </a>
          <a href="/gptData" target="_blank">
            <div v-zoom-in="{duration:800}" class="grand1" style="position: relative;height: fit-content" >
              <div class="back">
                <div class="photo">
                  <img class="image" style="border-radius: 9px" src="@/assets/knowledgenew2.png">
                </div>
              </div>
              <div class="back1">
                <div class="title1">Digital Intelligence<br>Integrated Solution</div>
                <div class="title2">
                  MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                </div>
              </div>
            </div>
          </a>
          <a href="/wisdom" target="_blank">
            <div v-zoom-in="{duration:1000}" class="grand1" style="position: relative;height: fit-content" >
              <div class="back">
                <div class="photo">
                  <img class="image" style="border-radius: 9px" src="@/assets/knowledgenew3.jpg">
                </div>
              </div>
              <div class="back1">
                <div class="title1">Data-driven Decision-making Platform</div>
                <div class="title2">
                  MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                </div>
              </div>
            </div>
          </a>
          <a href="/design" target="_blank">
            <div v-zoom-in="{duration:600}" class="grand1" style="position: relative;height: fit-content" >
              <div class="back">
                <div class="photo">
                  <img class="image" style="border-radius: 9px" src="@/assets/knowledgenew4.png">
                </div>
              </div>
              <div class="back1">
                <div class="title1"> Smart Employee Community</div>
                <div class="title2">
                  MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">

                </div>
              </div>
            </div>
          </a>
          <a href="/plat" target="_blank">
            <div v-zoom-in="{duration:800}" class="grand1" style="position: relative;height: fit-content" >
              <div class="back">
                <div class="photo">
                  <img class="image" style="border-radius: 9px" src="@/assets/knowledgenew5.png">
                </div>
              </div>
              <div class="back1">
                <div class="title1"> Smart E-commerce Platform</div>
                <div class="title2">
                  MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">

                </div>
              </div>
            </div>
          </a>
          <a href="/automation" target="_blank">
            <div v-zoom-in="{duration:1000}" class="grand1" style="position: relative;height: fit-content" >
              <div class="back">
                <div class="photo">
                  <img class="image" style="border-radius: 9px" src="@/assets/knowledgenew6.png">
                </div>
              </div>
              <div class="back1">
                <div class="title1"> Management Process<br> Automation Platform</div>
                <div class="title2">
                  MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>

    <div v-sliden-in="{}" class="ApplicationCards"  ref="APP5">
      <div class="titleDesign" ref="APP55" id="APP55">
        <div class="title" >
          Enterprise Self-Built AI Platform Services
        </div>
        <div class="title2" STYLE="    padding: 0 0.5vw 2vw 2vw">
          Assist client construct an AI cloud platform in a private environment.<br>
          <span style="color: #fd7117">For building GPT cloud platform needs,<span @click="clickConsult('Enterprise Self-Built AI Platform Services')" style="color:white;background-color: #282d7d;padding: 5px;border-radius: 9px;font-weight: bolder;font-size: 1.1vw">Contact Us</span>.</span>
        </div>
      </div>
      <div class="itemDesign" >
        <div class="pro12" style="display: grid;grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr);overflow-y: hidden">
          <!--              <div class="swiper-container-pc">-->
          <!--                <div class="swiper-wrapper">-->
          <!--                  <div class="swiper-slide" @click="constructPlat">-->
          <a href="/construct" target="_blank">
            <div v-zoom-in="{duration:600}" class="grand1" style="position: relative;height: fit-content" >
              <div class="back">
                <div class="photo">
                  <img class="image" src="@/assets/zijian1.png">
                </div>
              </div>
              <div class="back1">
                <div class="title1"> Oil & Gas Enterprise<br>Large Model Cloud Platform</div>
                <div class="title2">
                  MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">

                </div>
              </div>
            </div>
          </a>
          <!--                  </div>-->
          <!--                  <div class="swiper-slide" @click="business">-->
          <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
          <a href="/business" target="_blank">
            <div v-zoom-in="{duration:800}" class="grand1" style="position: relative;height: fit-content" >
              <div class="back">
                <div class="photo">
                  <img class="image" src="@/assets/zijian2.png">
                </div>
              </div>
              <div class="back1">
                <div class="title1">AI Development for Business Scenarios</div>
                <div class="title2">
                  MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                </div>
              </div>
            </div>
          </a>
          <!--                  </div>-->
          <!--                  <div class="swiper-slide" @click="company">-->
          <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
          <a href="/company2" target="_blank">
            <div v-zoom-in="{duration:600}" class="grand1" style="position: relative;height: fit-content" >
              <div class="back">
                <div class="photo">
                  <img class="image" src="@/assets/zijian3.png">
                </div>
              </div>
              <div class="back1">
                <div class="title1">   Data Governance</div>
                <div class="title2">
                  MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                </div>
              </div>
            </div>
          </a>
          <!--                  </div>-->
          <!--                  <div class="swiper-slide" @click="computingPower">-->
          <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
          <a href="/computingPower" target="_blank">
            <div v-zoom-in="{duration:800}" class="grand1" style="position: relative;height: fit-content" >
              <div class="back">
                <div class="photo">
                  <img class="image" src="@/assets/zijian4.png">
                </div>
              </div>
              <div class="back1">
                <div class="title1"> Computing Power Optimization</div>
                <div class="title2">
                  MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">

                </div>
              </div>
            </div>
          </a>
          <!--                  </div>-->

          <!--                </div>-->
          <!--                <div class="swiper-pagination"></div>-->
          <!--              </div>-->
        </div>
      </div>
    </div>
    <div v-sliden-in="{}" class="ApplicationCards"  ref="APP4">
      <div class="titleDesign"  ref="APP44" id="APP44">
        <div class="title" >
          AI Training And Transformation Consulting Services
        </div>
        <div class="title2">
          Formulation, design and consultation of AI transformation planning.<br>
          <span style="color: #fd7117">For training and transformation consulting needs,<span @click="clickConsult('AI Training And Transformation Consulting')" style="color:white;background-color: #282d7d;padding: 5px;border-radius: 9px;font-weight: bolder;font-size: 1.1vw">Contact Us</span>.</span>
        </div>
      </div>
      <div class="itemDesign" >
        <div class="pro2">
          <!--        油藏-->
          <a href="/traning" target="_blank">
            <div v-zoom-in="{duration:600}" class="grand2" >
              <div class="back">
                <div class="photo" style="height: 50.123vh">
                  <img class="image" loading="lazy" src="@/assets/puji2.jpg"
                       style="border-radius: 9px;"/>
                  <div class="mengban">
                    <div class="title1" style=" left: 1vh;">
                      AI Application Training
                    </div>
                    <div class="title1" style="right: 1vh;font-weight: unset;font-size: 1.12vw; ">
                      MORE &nbsp;<img style="width: 0.6vw;object-fit: contain;height: unset" src="@/assets/more_btn_icon.png">
                    </div>
                  </div>
                </div>
                <!--                  <div style="margin-left: 15px;">-->
                <!--                    <div class="title1">Promotional Videos</div>-->
                <!--                    <div class="title2">Dig deeper into your brand story-->
                <!--                    </div>-->
                <!--                  </div>-->
              </div>
            </div>
          </a>
          <!--        智能-->
          <a href="/consult" target="_blank">
            <div v-zoom-in="{duration:800}" class="grand2" >
              <div class="back">
                <div class="photo" style="height: 50.123vh">
                  <img class="image" loading="lazy" src="@/assets/puji1.png"
                       style="border-radius: 9px;"/>
                  <div class="mengban">
                    <div class="title1" style=" left: 1vh;">
                      AI Transformation Consulting
                    </div>
                    <div class="title1" style="right: 1vh;font-weight: unset;font-size:  1.12vw; ">
                      MORE &nbsp;<img style="width: 0.6vw;object-fit: contain;height: unset" src="@/assets/more_btn_icon.png">
                    </div>
                  </div>
                </div>
                <!--                  <div style="margin-left: 15px;">-->
                <!--                    <div class="title1">3D Animation</div>-->
                <!--                    <div class="title2">-->
                <!--                      Let your technology and products be presented in three dimensions</div>-->
                <!--                  </div>-->
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>



  </div>
</div>
</template>

<style scoped lang="scss">
.is-active{
  //color: red !important;
  background-color: #B4B4B4 !important;
}
  .customizationGPTsContainer{
    .headDesign{
      position: sticky;
      top: 6vh;
      background-color: #fbfbfb;
      z-index: 8;
    }
    .title111 {
      margin: 0 auto;
      text-align: center;
      height: 8.23733003708282vh;
      opacity: 1;
      font-size: 3vw;
      //color: #53A3F7;
      font-family: "Times New Roman";
      font-weight: 700;
      line-height: 4.23733003708282vh;
    }
    .heads{
      width: 100%;height: 8vh;background-color:white;display: grid; graycolor:black;
      background-color: #282c7d;
      grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
      .head{
        color: white;
        display: flex;
        border-left: whitesmoke solid 1px;
        //-webkit-border-bottom-left-radius: 20px;
        //box-shadow: -5px 0px 10px rgba(189, 206, 243, 0.58);
        //border-bottom: 2px solid gray;border-left: 2px solid;
        span{
          font-size: 1vw;
          margin: auto;
          font-weight: bolder;
        }
      }
      .head:hover{
        cursor: pointer;
      }
    }
    .GPTApplication{
      //display: flex;
      .ApplicationCards{
        display: flex;
        padding: 5vh 0;
      }
      .titleDesign{
        width:45% ;
        //background-color: blue;
        height: fit-content;
        position: sticky;
        top: 22vh;
        .title{
          font-size: 1.9vw;
          font-weight: bolder;
          color: #1b1e86;
          padding:2vw 2vw 1vw 2vw;
        }
        .title2{
          font-size: 1.2vw;
          padding:0 2vw 2vw 2vw;
          line-height: 2vw;
          //font-weight: bolder;
        }
      }
      .itemDesign{
        width: 55%;
      }
    }
  }
  .isSticky{
    position: sticky;
    top: 0;
    //padding-top: 2vh;
    //left: 0;
    z-index: 99999;
    background: white;
    width: 84vw;
  }
  .pro2 {

    border-radius: 9px;
    height: fit-content;
    width: 100%;
    z-index: 89156;
    display: grid;
    grid-template-columns:minmax(0, 1fr);
    column-gap: 0;

    .grand2 {
      border-radius: 9px;
      cursor: pointer;
      width: 100%;
      min-height: 30.65636588380717vh;
      padding: 0.98887515451174vh 0.5vw 0.98887515451174vh 0.5vw;
      opacity: 1;
      position: relative;

      .back {
        transition: transform 0.5s ease;
        width: 100%;
        height: 100%;
        background: white;;
        //box-shadow: 5px 0 14px 0px #D4E4FF;
        border: 2px solid #FFFFFF;
        border-radius: 9px;
      }

      .photo { overflow: hidden;
        border-radius: 9px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-color: transparent; /* 这里设置背景颜色为透明 */
        opacity: 1.25;
        display: flex;
        justify-content: center;
        text-align: center;
        width: 100%;
        height: 41vh;
        position: relative;
        .mengban{
          position: absolute;left: 0px;bottom: 0px;  width: 100%;height: 20%;background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);opacity: 0.9;border-bottom-right-radius: 9px;border-bottom-left-radius: 9px;
          .title1{
            position: absolute;height: 100%;color: white;margin-top:unset
          }
        }
      }

      .title1 {
        opacity: 4;
        margin-top: 2.22496909765142vh;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 2.71940667490729vh;
        font-size: 1.6vw;
        font-family: "Times New Roman";
        font-weight: bold;
        //color: #2168DB;
        line-height: 2.71940667490729vh;
      }

      .title2 {
        opacity: 4;
        margin: 1.94437577255871vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
        text-align: justify;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.80878105141537vw;
    font-family: "Times New Roman";
        font-weight: 500;
        //color: #2168DB;
        line-height: 2.71940667490729vh;
      }

      .consult {
        position: absolute;
        display: none;
    font-family: "Times New Roman";
        font-weight: 500;
        font-size: 0.80878105141537vw;
        bottom: 3.94437577255871vh;
        left: 1.85540150202195vw;
      }
    }

    .grand2:hover {
      .back {
        //transform: translateY(-10px);

      }
    }
  }
  .pro3 {
    height: fit-content;
    width: 100%;
    overflow-x: hidden;
    //z-index: 89156;

    .grand1 {
      cursor: pointer;
      width: 100%;
      border-radius: 6px;
      padding: 0.98887515451174vh 0 0 0;
      opacity: 1;
      height: 250px;
      position: relative;

      .back {
        width: 100%;
        height: fit-content;
        //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
        background-color: white;
        ////box-shadow: 5px 0 14px 0 #d4e4ff;
        //border-radius: 9px;
        border: white 2px solid;
        // border-radius:6px;
        // display: flex;
      }

      .back1 {
        bottom: 0;
        position: absolute;
        width: 100%;
        color: white;
        height: 30%;
        background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
        ////box-shadow: 5px 0 14px 0 #d4e4ff;
        border: white 2px solid;
        border-top: none;
        // border-radius:6px;
        display: flex;
        border-bottom-left-radius: 9px;
        border-bottom-right-radius: 9px;
        padding: 10px 15px;
        justify-content: space-between;
      }

      .photo { overflow: hidden;

        border-radius: 9px;
        border: #dddddd 3px solid;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        //background-color: #d4e4ff; /* 这里设置背景颜色为透明 */
        opacity: 1.25;
        width: 100%;
        height: 40vh;
      }

      .title1 {
        opacity: 4;
        text-align: left;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.6vw;;
        font-family: "Times New Roman";
        font-weight: bold;
        color: white;
        //line-height: 35px;
      }

      .title2 {
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 4;
        //width: 100%;
        height: 100%;
        // text-align: justify;
        margin: auto 0 0 auto;
        font-size: 1.12vw;
        font-family: Roboto, sans-serif;
        font-weight: 500;
        //color: #2168DB;
        //line-height: 35px;
      }
    }

  }
  .pro12 {
    height: fit-content;
    width: 100%;
    overflow-x: hidden;
    //z-index: 89156;


    .grand1 {
      cursor: pointer;
      width: 98%;
      border-radius: 6px;
      padding: 0.98887515451174vh 0 0 0;
      opacity: 1;
      height: 250px;
      position: relative;
      background-color: transparent;

      .back {
        width: 100%;
        height: fit-content;
        //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
        background-color: white;
        ////box-shadow: 5px 0 14px 0 #d4e4ff;
        //border-radius: 9px;
        border: white 2px solid;
        border-radius: 9px;
        // border-radius:6px;
        // display: flex;
      }

      .back1 {
        bottom: 0;
        position: absolute;
        width: 100%;
        color: white;
        height: 30%;
        background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
        ////box-shadow: 5px 0 14px 0 #d4e4ff;
        border: white 2px solid;
        border-top: none;
        // border-radius:6px;
        display: flex;
        border-bottom-left-radius: 9px;
        border-bottom-right-radius: 9px;
        padding: 10px 15px;
        justify-content: space-between;
      }

      .photo { overflow: hidden;

        border-radius: 9px;
        border: #dddddd 3px solid;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        //background-color: #d4e4ff; /* 这里设置背景颜色为透明 */
        opacity: 1.25;
        width: 100%;
        height: 40vh;
      }

      .title1 {
        opacity: 4;
        text-align: left;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.6vw;;
        font-family: "Times New Roman";
        font-weight: bold;
        color: white;
        //line-height: 35px;
      }

      .title2 {
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 4;
        //width: 100%;
        height: 100%;
        // text-align: justify;
        margin: auto 0 0 auto;
        font-size: 1.12vw;
        font-family: Roboto, sans-serif;
        font-weight: 500;
        //color: #2168DB;
        //line-height: 35px;
      }
    }

  }
</style>
